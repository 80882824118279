
* {
  box-sizing: border-box;
}
.App_App__DYVyw {
  flex:1 1;
  height:100%;
  text-align: center;
}

.App_Loading__hrmNo {
  flex:1 1;
  background: black;
  color:white;
}

.App_App-logo__iWffb {
  -webkit-animation: App_App-logo-spin__3F3oS infinite 20s linear;
          animation: App_App-logo-spin__3F3oS infinite 20s linear;
  height: 80px;
}

.App_App-header__HCndX {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App_App-title__GC33- {
  font-size: 1.5em;
}

.App_App-intro__2Gm6D {
  font-size: large;
}

/*@keyframes App-logo-spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}*/

@font-face {
    font-family: 'GothamCondensed';
    src: url(/static/media/GothamHTF-Black.50f2edc8.eot);
    src: url(/static/media/GothamHTF-Black.50f2edc8.eot) format('embedded-opentype'),
        url(/static/media/GothamHTF-Black.e3b484f0.woff2) format('woff2'),
        url(/static/media/GothamHTF-Black.8d57474c.woff) format('woff'),
        url(/static/media/GothamHTF-Black.a58c57f5.ttf) format('truetype'),
        url(/static/media/GothamHTF-Black.08482d0b.svg#GothamHTF-Black) format('svg');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'SFCompact';
    src: url(/static/media/SFCompactText-Regular.3b151a7c.eot);
    src: url(/static/media/SFCompactText-Regular.3b151a7c.eot?#iefix) format('embedded-opentype'),
        url(/static/media/SFCompactText-Regular.9419036a.woff2) format('woff2'),
        url(/static/media/SFCompactText-Regular.965ab44d.woff) format('woff'),
        url(/static/media/SFCompactText-Regular.2b648718.ttf) format('truetype'),
        url(/static/media/SFCompactText-Regular.9bae7564.svg#SFCompactText-Regular) format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Gotham';
    src: url(/static/media/GothamHTF-BoldCondensed.b3002791.eot);
    src: url(/static/media/GothamHTF-BoldCondensed.b3002791.eot?#iefix) format('embedded-opentype'),
        url(/static/media/GothamHTF-BoldCondensed.55f0ca78.woff2) format('woff2'),
        url(/static/media/GothamHTF-BoldCondensed.83d00fb3.woff) format('woff'),
        url(/static/media/GothamHTF-BoldCondensed.577cfb36.ttf) format('truetype'),
        url(/static/media/GothamHTF-BoldCondensed.212bcae9.svg#GothamHTF-BoldCondensed) format('svg');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'SFCompactBold';
    src: url(/static/media/SFCompactText-Bold.229e3dec.eot);
    src: url(/static/media/SFCompactText-Bold.229e3dec.eot?#iefix) format('embedded-opentype'),
        url(/static/media/SFCompactText-Bold.9547d3b3.woff2) format('woff2'),
        url(/static/media/SFCompactText-Bold.1e87e798.woff) format('woff'),
        url(/static/media/SFCompactText-Bold.813fd777.ttf) format('truetype'),
        url(/static/media/SFCompactText-Bold.422a4d76.svg#SFCompactText-Bold) format('svg');
    font-weight: bold;
    font-style: normal;
}


.style_materialDrawer__1dFvT {
	height: 100%;
}

.style_drawerContent__3sObc {
	height: 100%;
	padding: 0 10px 10px 10px;
	display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.style_modalTitle__1mZQc {
	display:flex;
}

.style_modalTitle__1mZQc h1 {
	font-weight: 300;
}


.style_modalContentContainer__3Yb9Z {
	flex:1 1;
	flex-shrink: 2;
	display: flex;
	justify-content: space-between;
	flex-direction: column;
	overflow: auto;
}

.style_modalActionContainer__eos5E {
	width: 100%;
	border-top: solid 1px rgba(0, 0, 0, 0.23);
	padding-top: 1.2em;
	margin-top: 1em;

	display: flex;
	flex-direction: row;
	justify-content: flex-end;
}




.editCreate_formContainer__12TqQ {
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	justify-content: flex-start;
	align-items: flex-start;
}

.editCreate_form__2hz1f {
	margin-top:30px;
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	justify-content: space-between;
	align-items: stretch;
}
.style_expandIcon__1deSg {
	color:white;
	cursor:pointer;
	background: black;
	border-radius: 50%;
	border:1px solid black;
	box-sizing: content-box;
}
.style_expandIcon__1deSg:hover {
	background: #333;
	border:1px solid black;
}

.style_inputCell__3bxOj {
	padding-top: 5px;
}

.styles_formContainer__MoCw4 {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
    align-items: flex-start;
    height: 100%;
    position: relative;
}

.styles_name__3Si5W {
    margin:10px;
    margin-bottom: 20px;
}

.styles_formContent__3KDPy {
    overflow: auto;
    width: 100%;
    padding-bottom: 55px;
    margin-top:7px;
}

.styles_formActions__1ZbwD {
    position: absolute;
    left:10px;
    bottom:10px;
}

.styles_form__1a5xU {
	margin-top:30px;
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	justify-content: space-between;
	align-items: stretch;
}
.styles_form__M0KNN {
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	justify-content: space-between;
	align-items: stretch;
}
.style_expandIcon__28tu9 {
	color:white;
	cursor:pointer;
	background: black;
	border-radius: 50%;
	border:1px solid black;
	box-sizing: content-box;
}
.style_expandIcon__28tu9:hover {
	background: #333;
	border:1px solid black;
}
.style_container__24Xpj {
	flex:1 1;
	display:flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.style_AddNewProductContainer__3AsYw {
	width:60%;
	height: 300px;
	border:1px solid #ccc;
	box-shadow: 0px 0px 23px 2px rgba(204,204,204,1);

	display:flex;
	flex-direction: column;
	justify-content: space-around;
	align-items: center;
}


.style_RecapOrderTableContainer__2rDQK {
	width: 100%;
}

.style_DatePicker__38ovp {
	height: 30px;
	width: 220px;
	padding: 10px;
}
.style_sidebarContainer__24JRl {
	display:flex;
	flex-direction: column;
	height: 100%;
}


.style_Link__2aHQR {
	display:block;
	height:50px;
	line-height: 50px;
	border:1px solid transparent;
	border-left-width: 3px;
	cursor:pointer;
	color:white;
}

.style_Link__2aHQR:hover {
	background-color: rgba(255,255,255,0.02);
}
.style_Link__2aHQR:active, .style_Link__2aHQR:visited {
	color:white;
}

.style_LinkActive__2vxgP {
	display:block;
	height:50px;
	line-height: 50px;
	border:1px solid transparent;
	border-left-width: 3px;
	cursor:pointer;
	color:white;


	border-left-color: orange;
	color:white;
	background-color:#242f35;
}
.style_LinkActive__2vxgP:hover {
	background-color:#242f35;
}
.style_isSubmenuActive__3mqQN {
	background-color: #1B98E0
}


.style_LinkLogout__1gsOL {
	width:190px;
	align-self: center;
	bottom:10px;
}
.common_loadingContainer__1fvK0 {
	width: 100%;
	height: 100%;
	position: fixed;
	top: 0;left: 0;
	background-color: rgba(0, 0, 0, 0.5);
	z-index: 1500;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}



.common_page__3e34Q {
	display:flex;
	height:100%;
	flex-direction: column;
}

.common_title__y0LlD {

	margin:0;
	padding:0;

	height:100px;
	border-bottom: 1px solid #ccc;
	box-shadow: 0 0 4px -2px;
}

.common_tableContainer__nh-bW {
	overflow: auto;
	width:100%;
}

.common_content__2cH9w {
	flex:1 1;
	width:100%;
	background-color: #f1f4f5;
}

.common_main__2XJHx {
	flex:1 1;
	height:100%;
	overflow: auto;
}

.common_grid__H7sz7 {
	padding-left:20px;
	padding-right: 20px;
}

.common_gridbox__2cbKx {
	min-width:300px;
}

.common_ButtonIcon__sxcYN {
	margin-right: 10px;
}

.common_ActionIcon__3XK2k {
	cursor:pointer;
}

.common_Hidden__2a2Eh {
	display:none;
}

.common_DatePickerContainer__d1Jzj, .common_gridboxContent__ZRK7o {
	height:100%;
	display:flex;
	flex:1 1;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	cursor:pointer;
}
.common_gridboxContent__ZRK7o {
	flex-direction: column;
	justify-content: space-around;
}
.common_DatePickerContainer__d1Jzj .common_DatePicker__2b7k6 {
	padding:20px;
	font-size:20px;
	outline: none;
}
.Layout_PageContainer__3mTJP {
	height: 100%;

	padding-left: 10px;
	padding-right: 10px;

	display: flex;
	flex-direction: column;
	align-items: center;
}
.Layout_PageContainerFull__3dwJC {
	padding-left: 0px;
	padding-right: 0px;
}

.Layout_contentContainer__2pmlL {
	flex: 1 1;
	width: 100%;
	overflow: auto;
}
.Header_container__3etP0 {
	display:flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;

	height: 90px;
	width: 100%;
}

.Header_titleContainer__NmH2n {
	display: flex;
	flex-direction: row;
	align-items: center;
}

.Header_title__1Ao1p {
	font-size: 30px;
	font-weight: 500;
}

.Header_actionContainer__2HI5X {
	display: flex;
	flex-direction: row;
	align-items: flex-end;
	justify-content: space-between;
}
.HeaderUnlogged_container__CUGwX {
	display:flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;

	height: 90px;
	width: 100%;
	max-width: 1000px;
}

.HeaderUnlogged_logoContainer__34dvU {
	display: flex;
	flex-direction: row;
	align-items: center;
	cursor: pointer;
}

.HeaderUnlogged_logo__3Wukt {
	height:100px;
}

.HeaderUnlogged_title__tQvzx {
	margin-left: 20px;
	font-size: 30px;
	font-weight: 500;
}
.style_Navlink__3hGX7 {
    text-decoration: none;
}

.style_ButtonIcon__6bIwI {
	margin-right: 10px;
}

.style_ActionIcon__vetdf {
	cursor:pointer;
}

.style_ClientOrderSeparator__28cAu {
	color: #333;
	padding-top: 60px;
	font-weight: bold;
	font-size: 1.5em;
}
.style_ClientOrderSeparatorFirst__1VhTc {
	padding-top: 0px;
}
.editCreate_formContainer__2sC0Y {
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	justify-content: flex-start;
	align-items: flex-start;
}

.editCreate_form__dHIAS {
	margin-top:30px;
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	justify-content: space-between;
	align-items: stretch;
}
.style_content__12nGn {
    display: flex;
}
.styles_formContainer__3jPPP {
    display: flex;
    flex:1 1;
	flex-direction: column;
	flex-wrap: wrap;
	justify-content: flex-start;
    align-items: center;
	padding:10px;
	padding-top:30px;
	background-color: #f5f5f5;
}

.styles_paper__1Rq8O {
	width:100%;
	margin-bottom:20px;
	text-align: left;
}

.styles_paper__1Rq8O h2 {
	padding:10px 20px 10px 20px;
	margin:0;
	width:100%;
	border-bottom: 1px solid #f5f5f5;
}

.styles_paper__1Rq8O .styles_content__2ELVr {
	width: 100%;
	padding: 20px 20px;
}

.styles_chipContainer__3mcRH {
    margin-top: 10px;
}
.styles_chipContainer__3mcRH > div {
    margin-right: 10px;
}

.styles_btnContainer__1cUJj {
    margin-top: 10px;
}

.styles_btnContainer__1cUJj .styles_btn__2SYGN {
    margin-right: 10px;
}
.style_ActionButtonContainer__3OxeB {
	margin-top:100px;
	margin-bottom:100px;
	width:100%;
	display:flex;
	justify-content: center;
	align-items: center;
}

.style_ActionButton__Gh7kO {
	width:300px;
	height:40px;
}

.style_CommandContainer__1NHcY {
	padding-top:30px;
	display:flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	padding-left: 10px;
}

.style_CommandContainer__1NHcY .style_HeaderContainer__1twq5 {
	display:flex;
	flex-direction: row;
	width:100%;
	justify-content: space-between;
	align-items: center;
	padding:10px;
}

.style_CommandContainer__1NHcY .style_HeaderContainer__1twq5 .style_SaveButtonContainer__1RJLP {

}

.style_CommandContainer__1NHcY .style_HeaderContainer__1twq5 .style_SaveButtonContainer__1RJLP .style_ActionButtonSave__124po {
	height:40px;
}
.style_CommandContainer__1NHcY .style_HeaderContainer__1twq5 .style_SaveButtonContainer__1RJLP .style_ActionButtonSave__124po .style_ActionButtonSaveIcon__3t1cz {
	margin-right:20px;
}


.style_CommandContainer__1NHcY .style_HeaderContainer__1twq5 .style_DatePickerContainer__1xCh6 {
	display:flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	cursor:pointer;
}
.style_CommandContainer__1NHcY .style_HeaderContainer__1twq5 .style_DatePickerContainer__1xCh6 .style_DatePicker__2J60z {
	padding:20px;
	width: 350px;
	font-size:20px;
	outline: none;
}


.style_ClientOrdersContainer__3aPeU {
	width:100%;
	display: flex;
	flex-direction: column;
	padding:20px;
}
.style_ClientOrdersTableContainer__3rwY9 {
	width:100%;
}
.style_ClientOrdersActions__36trq {
	display:flex;
	justify-content: space-around;
}



.style_ShopOrderContainer__TBMV4 {
	margin-bottom:50px;
}
.style_CommentText__1Fcx4 {
	margin-top:50px !important;
}

.style_ShopSpecialOrderContainer__1rfHK {
	width:100%;
	padding:20px;
}
.style_Hidden__1OLOd {
	display:none;
}




.style_EmailFormContainer__1FGtO {
	width:100%;
	text-align: left;
}

.style_EmailInput__1l-rV {
	width: 200px;
	padding: 10px;
}






.styles_formContainer__3ANSi {
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	justify-content: flex-start;
    align-items: center;
	padding:10px;
	padding-top:30px;
	background-color: #f5f5f5;
}

.styles_paper__ykMjZ {
	width:100%;
	margin-bottom:20px;
	text-align: left;
}

.styles_paper__ykMjZ h2 {
	padding:10px 20px 10px 20px;
	margin:0;
	width:100%;
	border-bottom: 1px solid #f5f5f5;
}

.styles_paper__ykMjZ .styles_content__PxuFS {
	width: 100%;
	padding: 20px 20px;
}
.styles_formContainer__2limW {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
    align-items: flex-start;
    height: 100%;
    position: relative;
}

.styles_formContent__vigAS {
    overflow: auto;
    width: 100%;
    padding-bottom: 55px;
    margin-top:7px;
}

.styles_formActions__3TAWt {
    position: absolute;
    left:10px;
    bottom:10px;
}

.styles_form__1PGs5 {
	margin-top:30px;
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	justify-content: space-between;
	align-items: stretch;
}

.styles_textField__29PxS {
    min-width: 150px;
}

.styles_container__LsxXd {
	padding-top:30px;
	display:flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
}
.style_grid__1Ddar {
	display:flex;
	justify-content: center;
	flex-wrap: wrap;
	flex-direction: row;
	margin-left: -30px;
	margin-top:20px;
}

.style_box__1-MNp {
	flex: 0 0 auto;
	min-height:100px;
	min-width: 300px;
	margin-left: 30px;
	margin-bottom:20px;

	background-color: white;
	border-color:#ccc;
	border-radius: 0.5px;
	box-shadow: 0 2px 6px rgba(0, 0, 0, 0.25);

	display:flex;
	flex-direction: row;
}

.style_boxContent__373UW {
	flex:1 1;
	display:flex;
	flex-direction: column;

}

.style_boxContentTitle__1ZReF {
	height:30px;
	line-height: 30px;

	border-bottom:1px solid #ccc;

    text-align: left;
    padding-left: 10px;
    color: #84909c;
    /* text-transform: uppercase; */
    /* font-family: Roboto,sans-serif !important; */
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0.3px;
}
.style_boxContentMain__1X4ON {
	flex:1 1;
	width:100%;
}
.style_Container__SM0e1 {
    padding: 20px;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-content: center;

    width: 300px;
    margin:auto;
}

.style_TextField__2OTMA {
    margin-bottom: 10px !important;
}
.style_Container__3z9Rz {
    padding: 20px;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-content: center;

    width: 300px;
    margin:auto;
}

.style_TextField__1yAK1 {
    margin-bottom: 10px !important;
}
.styles_container__2Gc_h {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
}
.style_Container__VCZd8 {

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-content: center;
    height: 400px;
    width: 100%;
    margin:auto;
}

.style_TextField__2bP26 {
    margin-bottom: 10px !important;
}


.style_formContainer__3uk0m {
    padding:10px;
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}
