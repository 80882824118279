@font-face {
    font-family: 'GothamCondensed';
    src: url('./GothamHTF-Black.eot');
    src: url('./GothamHTF-Black.eot') format('embedded-opentype'),
        url('./GothamHTF-Black.woff2') format('woff2'),
        url('./GothamHTF-Black.woff') format('woff'),
        url('./GothamHTF-Black.ttf') format('truetype'),
        url('./GothamHTF-Black.svg#GothamHTF-Black') format('svg');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'SFCompact';
    src: url('./SFCompactText-Regular.eot');
    src: url('./SFCompactText-Regular.eot?#iefix') format('embedded-opentype'),
        url('./SFCompactText-Regular.woff2') format('woff2'),
        url('./SFCompactText-Regular.woff') format('woff'),
        url('./SFCompactText-Regular.ttf') format('truetype'),
        url('./SFCompactText-Regular.svg#SFCompactText-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Gotham';
    src: url('./GothamHTF-BoldCondensed.eot');
    src: url('./GothamHTF-BoldCondensed.eot?#iefix') format('embedded-opentype'),
        url('./GothamHTF-BoldCondensed.woff2') format('woff2'),
        url('./GothamHTF-BoldCondensed.woff') format('woff'),
        url('./GothamHTF-BoldCondensed.ttf') format('truetype'),
        url('./GothamHTF-BoldCondensed.svg#GothamHTF-BoldCondensed') format('svg');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'SFCompactBold';
    src: url('./SFCompactText-Bold.eot');
    src: url('./SFCompactText-Bold.eot?#iefix') format('embedded-opentype'),
        url('./SFCompactText-Bold.woff2') format('woff2'),
        url('./SFCompactText-Bold.woff') format('woff'),
        url('./SFCompactText-Bold.ttf') format('truetype'),
        url('./SFCompactText-Bold.svg#SFCompactText-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
}

