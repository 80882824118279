.PageContainer {
	height: 100%;

	padding-left: 10px;
	padding-right: 10px;

	display: flex;
	flex-direction: column;
	align-items: center;
}
.PageContainerFull {
	padding-left: 0px;
	padding-right: 0px;
}

.contentContainer {
	flex: 1;
	width: 100%;
	overflow: auto;
}