.grid {
	display:flex;
	justify-content: center;
	flex-wrap: wrap;
	flex-direction: row;
	margin-left: -30px;
	margin-top:20px;
}

.box {
	flex: 0 0 auto;
	min-height:100px;
	min-width: 300px;
	margin-left: 30px;
	margin-bottom:20px;

	background-color: white;
	border-color:#ccc;
	border-radius: 0.5px;
	box-shadow: 0 2px 6px rgba(0, 0, 0, 0.25);

	display:flex;
	flex-direction: row;
}

.boxContent {
	flex:1;
	display:flex;
	flex-direction: column;

}

.boxContentTitle {
	height:30px;
	line-height: 30px;

	border-bottom:1px solid #ccc;

    text-align: left;
    padding-left: 10px;
    color: #84909c;
    /* text-transform: uppercase; */
    /* font-family: Roboto,sans-serif !important; */
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0.3px;
}
.boxContentMain {
	flex:1;
	width:100%;
}