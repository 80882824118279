.Container {
    padding: 20px;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-content: center;

    width: 300px;
    margin:auto;
}

.TextField {
    margin-bottom: 10px !important;
}