.formContainer {
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	justify-content: flex-start;
    align-items: center;
	padding:10px;
	padding-top:30px;
	background-color: #f5f5f5;
}

.paper {
	width:100%;
	margin-bottom:20px;
	text-align: left;
}

.paper h2 {
	padding:10px 20px 10px 20px;
	margin:0;
	width:100%;
	border-bottom: 1px solid #f5f5f5;
}

.paper .content {
	width: 100%;
	padding: 20px 20px;
}