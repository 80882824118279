.container {
	display:flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;

	height: 90px;
	width: 100%;
	max-width: 1000px;
}

.logoContainer {
	display: flex;
	flex-direction: row;
	align-items: center;
	cursor: pointer;
}

.logo {
	height:100px;
}

.title {
	margin-left: 20px;
	font-size: 30px;
	font-weight: 500;
}