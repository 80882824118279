.loadingContainer {
	width: 100%;
	height: 100%;
	position: fixed;
	top: 0;left: 0;
	background-color: rgba(0, 0, 0, 0.5);
	z-index: 1500;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}



.page {
	display:flex;
	height:100%;
	flex-direction: column;
}

.title {

	margin:0;
	padding:0;

	height:100px;
	border-bottom: 1px solid #ccc;
	box-shadow: 0 0 4px -2px;
}

.tableContainer {
	overflow: auto;
	width:100%;
}

.content {
	flex:1;
	width:100%;
	background-color: #f1f4f5;
}

.main {
	flex:1;
	height:100%;
	overflow: auto;
}

.grid {
	padding-left:20px;
	padding-right: 20px;
}

.gridbox {
	min-width:300px;
}

.ButtonIcon {
	margin-right: 10px;
}

.ActionIcon {
	cursor:pointer;
}

.Hidden {
	display:none;
}

.DatePickerContainer, .gridboxContent {
	height:100%;
	display:flex;
	flex:1;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	cursor:pointer;
}
.gridboxContent {
	flex-direction: column;
	justify-content: space-around;
}
.DatePickerContainer .DatePicker {
	padding:20px;
	font-size:20px;
	outline: none;
}