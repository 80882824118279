.ActionButtonContainer {
	margin-top:100px;
	margin-bottom:100px;
	width:100%;
	display:flex;
	justify-content: center;
	align-items: center;
}

.ActionButton {
	width:300px;
	height:40px;
}

.CommandContainer {
	padding-top:30px;
	display:flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	padding-left: 10px;
}

.CommandContainer .HeaderContainer {
	display:flex;
	flex-direction: row;
	width:100%;
	justify-content: space-between;
	align-items: center;
	padding:10px;
}

.CommandContainer .HeaderContainer .SaveButtonContainer {

}

.CommandContainer .HeaderContainer .SaveButtonContainer .ActionButtonSave {
	height:40px;
}
.CommandContainer .HeaderContainer .SaveButtonContainer .ActionButtonSave .ActionButtonSaveIcon {
	margin-right:20px;
}


.CommandContainer .HeaderContainer .DatePickerContainer {
	display:flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	cursor:pointer;
}
.CommandContainer .HeaderContainer .DatePickerContainer .DatePicker {
	padding:20px;
	width: 350px;
	font-size:20px;
	outline: none;
}


.ClientOrdersContainer {
	width:100%;
	display: flex;
	flex-direction: column;
	padding:20px;
}
.ClientOrdersTableContainer {
	width:100%;
}
.ClientOrdersActions {
	display:flex;
	justify-content: space-around;
}



.ShopOrderContainer {
	margin-bottom:50px;
}
.CommentText {
	margin-top:50px !important;
}

.ShopSpecialOrderContainer {
	width:100%;
	padding:20px;
}
.Hidden {
	display:none;
}




.EmailFormContainer {
	width:100%;
	text-align: left;
}

.EmailInput {
	width: 200px;
	padding: 10px;
}





