.Container {

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-content: center;
    height: 400px;
    width: 100%;
    margin:auto;
}

.TextField {
    margin-bottom: 10px !important;
}


.formContainer {
    padding:10px;
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}