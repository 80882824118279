.expandIcon {
	color:white;
	cursor:pointer;
	background: black;
	border-radius: 50%;
	border:1px solid black;
	box-sizing: content-box;
}
.expandIcon:hover {
	background: #333;
	border:1px solid black;
}