.materialDrawer {
	height: 100%;
}

.drawerContent {
	height: 100%;
	padding: 0 10px 10px 10px;
	display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.modalTitle {
	display:flex;
}

.modalTitle h1 {
	font-weight: 300;
}


.modalContentContainer {
	flex:1;
	flex-shrink: 2;
	display: flex;
	justify-content: space-between;
	flex-direction: column;
	overflow: auto;
}

.modalActionContainer {
	width: 100%;
	border-top: solid 1px rgba(0, 0, 0, 0.23);
	padding-top: 1.2em;
	margin-top: 1em;

	display: flex;
	flex-direction: row;
	justify-content: flex-end;
}



