.Navlink {
    text-decoration: none;
}

.ButtonIcon {
	margin-right: 10px;
}

.ActionIcon {
	cursor:pointer;
}

.ClientOrderSeparator {
	color: #333;
	padding-top: 60px;
	font-weight: bold;
	font-size: 1.5em;
}
.ClientOrderSeparatorFirst {
	padding-top: 0px;
}