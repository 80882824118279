.formContainer {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
    align-items: flex-start;
    height: 100%;
    position: relative;
}

.name {
    margin:10px;
    margin-bottom: 20px;
}

.formContent {
    overflow: auto;
    width: 100%;
    padding-bottom: 55px;
    margin-top:7px;
}

.formActions {
    position: absolute;
    left:10px;
    bottom:10px;
}

.form {
	margin-top:30px;
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	justify-content: space-between;
	align-items: stretch;
}