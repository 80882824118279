.container {
	flex:1;
	display:flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.AddNewProductContainer {
	width:60%;
	height: 300px;
	border:1px solid #ccc;
	box-shadow: 0px 0px 23px 2px rgba(204,204,204,1);

	display:flex;
	flex-direction: column;
	justify-content: space-around;
	align-items: center;
}


.RecapOrderTableContainer {
	width: 100%;
}

.DatePicker {
	height: 30px;
	width: 220px;
	padding: 10px;
}