.sidebarContainer {
	display:flex;
	flex-direction: column;
	height: 100%;
}


.Link {
	display:block;
	height:50px;
	line-height: 50px;
	border:1px solid transparent;
	border-left-width: 3px;
	cursor:pointer;
	color:white;
}

.Link:hover {
	background-color: rgba(255,255,255,0.02);
}
.Link:active, .Link:visited {
	color:white;
}

.LinkActive {
	display:block;
	height:50px;
	line-height: 50px;
	border:1px solid transparent;
	border-left-width: 3px;
	cursor:pointer;
	color:white;


	border-left-color: orange;
	color:white;
	background-color:#242f35;
}
.LinkActive:hover {
	background-color:#242f35;
}
.isSubmenuActive {
	background-color: #1B98E0
}


.LinkLogout {
	width:190px;
	align-self: center;
	bottom:10px;
}